import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MediaType } from 'types/enums/MediaType';
import { PresentCategory } from 'types/interfaces/presents/PresentCategory';
import { PresentInfo } from 'types/interfaces/presents/PresentInfo';
import { PresentRequest } from 'types/interfaces/presents/PresentRequest';
import {
  ExtendedUserDetails,
  UserEditOptions,
} from 'types/interfaces/user/UserDetails';
import { MediaAccess, UserProfile } from 'types/interfaces/user/UserProfile';

interface ProfileState {
  isLoading: boolean;
  profile: UserProfile | null;
  profileDetails: ExtendedUserDetails | null;
  profileOptions: UserEditOptions | null;
  profileMediaAccess: Record<string, MediaAccess>;

  presentsCategories: PresentCategory[];
  isPresentsCategoriesLoading: boolean;

  presentsWishlist: PresentInfo[];
  isPresentsWishlistLoading: boolean;

  presentsRequests: PresentRequest[];
  isPresentsRequestsLoading: boolean;
}

const initialState: ProfileState = {
  isLoading: true,
  profile: null,
  profileDetails: null,
  profileOptions: null,
  profileMediaAccess: {},

  presentsCategories: [],
  isPresentsCategoriesLoading: true,

  presentsWishlist: [],
  isPresentsWishlistLoading: true,

  presentsRequests: [],
  isPresentsRequestsLoading: true,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserProfile | null>) {
      state.profile = action.payload;
    },
    setProfileLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateProfile(state, action: PayloadAction<Partial<UserProfile>>) {
      if (state.profile)
        state.profile = { ...state.profile, ...action.payload };
    },

    resetState() {
      return initialState;
    },
    setProfileDetails(state, action: PayloadAction<ExtendedUserDetails>) {
      state.profileDetails = action.payload;
    },
    updateProfileDetails(
      state,
      action: PayloadAction<Partial<ExtendedUserDetails>>
    ) {
      if (state.profileDetails) {
        state.profileDetails = { ...state.profileDetails, ...action.payload };
      }
    },
    setProfileOptions(state, action: PayloadAction<UserEditOptions>) {
      state.profileOptions = action.payload;
    },
    setProfileMediaAccess(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        access: {
          accesses: Record<MediaType, string[]>;
          mass_accesses: MediaType[];
        };
      }>
    ) {
      const { userId, contactId, access } = action.payload;

      state.profileMediaAccess[`${userId}-${contactId}`] = access;
    },

    setPresentsCategories(state, action: PayloadAction<PresentCategory[]>) {
      state.presentsCategories = action.payload;
    },
    setIsPresentsCategoriesLoading(state, action: PayloadAction<boolean>) {
      state.isPresentsCategoriesLoading = action.payload;
    },
    setPresentsRequests(state, action: PayloadAction<PresentRequest[]>) {
      state.presentsRequests = action.payload;
    },
    setIsPresentsRequestsLoading(state, action: PayloadAction<boolean>) {
      state.isPresentsRequestsLoading = action.payload;
    },
    updatePresentRequest(
      state,
      action: PayloadAction<Partial<PresentRequest>>
    ) {
      if (!action.payload.id) return;

      state.presentsRequests = state.presentsRequests.map((requestItem) => {
        if (requestItem.id === action.payload.id) {
          return { ...requestItem, ...action.payload };
        }

        return requestItem;
      });
    },
    addNewPresentRequest(state, action: PayloadAction<PresentRequest>) {
      const newPresentRequest = action.payload;

      if (
        state.presentsRequests.find(
          (requestItem) => requestItem.id === newPresentRequest.id
        )
      ) {
        state.presentsRequests = state.presentsRequests.map((requestItem) => {
          if (requestItem.id === newPresentRequest.id) {
            return { ...requestItem, ...newPresentRequest };
          }

          return requestItem;
        });

        return;
      }

      state.presentsRequests = [newPresentRequest, ...state.presentsRequests];
    },
    setPresentsWishlist(state, action: PayloadAction<PresentInfo[]>) {
      state.presentsWishlist = action.payload;
    },
    setIsPresentsWishlistLoading(state, action: PayloadAction<boolean>) {
      state.isPresentsWishlistLoading = action.payload;
    },

    addPresentToWishlist(state, action: PayloadAction<PresentInfo>) {
      if (
        state.presentsWishlist.find(
          (presentItem) => presentItem.id === action.payload.id
        )
      )
        return;

      state.presentsWishlist = [action.payload, ...state.presentsWishlist];
    },
    removePresentFromWishlist(
      state,
      action: PayloadAction<{ presentInfoId: number }>
    ) {
      state.presentsWishlist = state.presentsWishlist.filter(
        (presentInfoItem) => presentInfoItem.id !== action.payload.presentInfoId
      );
    },
  },
});

export const {
  setProfile,
  setProfileLoading,
  updateProfile,
  resetState,
  setProfileDetails,
  updateProfileDetails,
  setProfileOptions,
  setProfileMediaAccess,

  setPresentsCategories,
  setIsPresentsCategoriesLoading,
  setPresentsRequests,
  setIsPresentsRequestsLoading,
  addNewPresentRequest,
  updatePresentRequest,
  setPresentsWishlist,
  setIsPresentsWishlistLoading,
  addPresentToWishlist,
  removePresentFromWishlist,
} = profileSlice.actions;

export const profile = profileSlice.reducer;
