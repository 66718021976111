import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UnreadCounters } from 'types/interfaces/UnreadCounters';

import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'helpers/localStorage';

const DARK_THEME = 'dark_theme';

interface IFeatureFlags {
  isEnabledNotificationsSound: boolean;
  isEnabledIcebreakersChains: boolean;
  isEnabledPresents: boolean;
}
interface ICommonState {
  loading: boolean;
  presentsRequestsCount: number;
  unreadCount: Record<string, UnreadCounters>;
  prevPagePathname: string;
  currentLang: string;
  supportedLangs: string[];
  featureFlags: IFeatureFlags;
  isEnabledDarkTheme: boolean;

  commonToastErrorMessage: string | null;
}

const initialState: ICommonState = {
  loading: false,

  presentsRequestsCount: 0,
  unreadCount: {},
  prevPagePathname: '',
  currentLang: 'en',
  supportedLangs: [],
  featureFlags: {
    isEnabledNotificationsSound: false,
    isEnabledIcebreakersChains: false,
    isEnabledPresents: false,
  },
  isEnabledDarkTheme: !!getLocalStorageItem(DARK_THEME),

  commonToastErrorMessage: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setInitState(
      state,
      action: PayloadAction<
        Omit<
          ICommonState,
          'prevPagePathname' | 'isEnabledDarkTheme' | 'commonToastErrorMessage'
        >
      >
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    updateUnreadCounters(
      state,
      action: PayloadAction<Record<string, UnreadCounters>>
    ) {
      state.unreadCount = { ...state.unreadCount, ...action.payload };
    },

    increasePresentsRequestsCount(state) {
      state.presentsRequestsCount += 1;
    },

    decreasePresentsRequestsCount(state) {
      if (state.presentsRequestsCount >= 1) state.presentsRequestsCount -= 1;
    },

    setPrevPagePathname(state, action: PayloadAction<string>) {
      state.prevPagePathname = action.payload;
    },

    setCurrentLang(state, action: PayloadAction<string>) {
      state.currentLang = action.payload;
    },

    setIsEnabledNotificationsSound(state, action: PayloadAction<boolean>) {
      state.featureFlags.isEnabledNotificationsSound = action.payload;
    },

    setIsEnabledDarkTheme(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        setLocalStorageItem(DARK_THEME, true);
      } else {
        removeLocalStorageItem(DARK_THEME);
      }

      state.isEnabledDarkTheme = action.payload;
    },

    setCommonToastError(state, action: PayloadAction<string | null>) {
      state.commonToastErrorMessage = action.payload;
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setInitState,
  resetState,
  setLoading,
  updateUnreadCounters,
  setPrevPagePathname,
  setCurrentLang,
  setIsEnabledNotificationsSound,
  setIsEnabledDarkTheme,
  increasePresentsRequestsCount,
  decreasePresentsRequestsCount,

  setCommonToastError,
} = commonSlice.actions;

export const common = commonSlice.reducer;
