import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IcebreakerFilters } from 'types/enums/icebreakers/IcebreakerFilters';
import { IcebreakersCoverage } from 'types/enums/icebreakers/IcebreakersCoverage';
import { IcebreakersTab } from 'types/enums/icebreakers/IcebreakersTab';
import { Icebreaker } from 'types/interfaces/chat/Icebreaker';

interface IcebreakersState {
  icebreakers: Icebreaker[];
  coverageList: Record<string, IcebreakersCoverage> | null;
  nextIcebreakers: string | null;
  isLoading: boolean;
  loadingIcebreakers: (string | number)[];
  filters: IcebreakerFilters;
}

const initialState: IcebreakersState = {
  icebreakers: [],
  coverageList: null,
  nextIcebreakers: null,
  isLoading: false,
  loadingIcebreakers: [],
  filters: {
    type: null,
    userId: null,
    tab: IcebreakersTab.All,
    coverage: IcebreakersCoverage.All,
  },
};

const icebreakersSlice = createSlice({
  name: 'icebreakers',
  initialState,
  reducers: {
    setIcebreakers(state, action: PayloadAction<Icebreaker[]>) {
      state.icebreakers = action.payload;
    },

    setCoverageList(
      state,
      action: PayloadAction<Record<string, IcebreakersCoverage>>
    ) {
      state.coverageList = action.payload;
    },

    addIcebreaker(state, action: PayloadAction<Icebreaker>) {
      state.icebreakers = [action.payload, ...state.icebreakers];
    },

    addIcebreakers(state, action: PayloadAction<Icebreaker[]>) {
      state.icebreakers = [...action.payload, ...state.icebreakers];
    },

    removeIcebreaker(state, action: PayloadAction<number>) {
      state.icebreakers = state.icebreakers.filter(
        (icebreaker) => icebreaker.id !== action.payload
      );
    },

    setNextIcebreakers(state, action: PayloadAction<string | null>) {
      state.nextIcebreakers = action.payload;
    },

    updateIcebreaker(
      state,
      action: PayloadAction<{
        newIcebreaker: Icebreaker;
        userId: string | null;
      }>
    ) {
      const { newIcebreaker, userId } = action.payload;

      state.icebreakers = state.icebreakers.map((icebreakerItem) => {
        if (icebreakerItem.id === newIcebreaker.id) {
          return {
            ...newIcebreaker,
            user: icebreakerItem.user || newIcebreaker.user,
          };
        }

        if (
          icebreakerItem.type === newIcebreaker.type &&
          userId === icebreakerItem?.user?.ulid_id
        ) {
          return {
            ...icebreakerItem,
            is_active: 0,
            user: icebreakerItem.user || newIcebreaker.user,
          };
        }

        return icebreakerItem;
      });
    },

    setIsIcebreakersLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    addLoadingIcebreaker(state, action: PayloadAction<number | string>) {
      state.loadingIcebreakers = [...state.loadingIcebreakers, action.payload];
    },

    removeLoadingIcebreaker(state, action: PayloadAction<number | string>) {
      state.loadingIcebreakers = state.loadingIcebreakers.filter(
        (icebreakerId) => icebreakerId !== action.payload
      );
    },

    setIcebreakerFilters(
      state,
      action: PayloadAction<Partial<IcebreakerFilters>>
    ) {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setIcebreakers,
  setCoverageList,
  addIcebreaker,
  addIcebreakers,
  removeIcebreaker,
  setNextIcebreakers,
  updateIcebreaker,
  setIsIcebreakersLoading,
  addLoadingIcebreaker,
  removeLoadingIcebreaker,
  setIcebreakerFilters,

  resetState,
} = icebreakersSlice.actions;

export const icebreakers = icebreakersSlice.reducer;
