import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';
import { NotificationTypes } from 'types/enums/NotificationTypes';
import { PresentStatus } from 'types/enums/presents/PresentStatus';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogTabCounters } from 'types/interfaces/chat/ChatDialogTabCounters';
import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { Mail } from 'types/interfaces/mails/Mail';
import { INotification } from 'types/interfaces/Notifications';
import { PresentRequest } from 'types/interfaces/presents/PresentRequest';
import { UnreadCounters as IUnreadCounters } from 'types/interfaces/UnreadCounters';
import { UserContact } from 'types/interfaces/user/UserContact';
import { MediaAccess } from 'types/interfaces/user/UserProfile';

import { setXUlidId } from 'api/httpClient';
import { TrackingApi } from 'api/TrackingApi';
import { tabBadge } from 'helpers/browserBadge';
import { getPhotoPlaceholders } from 'helpers/photoPlaceholders';
import { isDialogsPage } from 'helpers/route';
import { logout } from 'store/auth/thunks';
import {
  increasePresentsRequestsCount,
  updateUnreadCounters,
} from 'store/common/commonSlice';
import { getIsEnabledNotificationsSoundSelector } from 'store/common/selectors';
import {
  markMailsChainAsBlocked,
  updateMailsChatContactLimits,
} from 'store/mails/mailsSlice';
import { addNewMailFromSocketThunk } from 'store/mails/thunks';
import {
  addTypingContact,
  markChatAsBlocked,
  markDialogAsNotNew,
  markMessagesAsRead,
  removeDialogMessage,
  removeTypingContact,
  setDialogsCounters,
  updateChatContactLimits,
  updateDialogLastMessageIncomingStatus,
  updateDialogReadStatus,
} from 'store/messenger/messengerSlice';
import { addNewMessageFromSocketThunk } from 'store/messenger/thunks';
import {
  addNewNotification,
  removeNotification,
} from 'store/notifications/notificationsSlice';
import { readNotificationThunk } from 'store/notifications/thunks';
import {
  addNewPresentRequest,
  setProfileMediaAccess,
} from 'store/profile/profileSlice';

import notificationSound from 'assets/audio/notification.mp3';

import { QueryKeys } from './useSystemSearchQueries';
import { useToast } from './useToast';

const CONTACT_TYPING_INTERVAL = 4000;

enum WsActionTypes {
  // * mailsHandler
  InMailSend = 'inmail_send',

  // * messageHandler
  Send = 'send',
  Read = 'read',
  Fail = 'fail',
  DeleteMessage = 'deleted',
  RemoveNewBadge = 'new_dialog_flag_removed',
  Typing = 'typing',

  // * notificationsHandler
  PhotoModerationFail = 'photo_moderation_fail',
  NewNotification = 'new_notification',
  RepeatNotification = 'repeat_notification',
  DoneNotification = 'done_notification',
  DeletedNotification = 'deleted_notification',
  ForceLogout = 'force_logout',

  // * limitsHandler
  LimitChanged = 'limit_changed',

  // * systemHandler
  TabCounters = 'tab_counters_updated',
  UnreadCounters = 'unread_counters_updated',
  UserBlockedByContact = 'trusted_user_block',

  // * presentsHandler
  PresentStatusChange = 'real_gift_process_status_changed',
}

const audio = new Audio(notificationSound);

const DELAY_FOR_NOTIFICATION_REMOVE = 2 * 1000;

export const useWebSocketHandlers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { pathname } = useLocation();

  const typingContactsTimeouts = useRef<Record<string, NodeJS.Timeout>>({});

  const { showNotificationToast, showWarningToast } = useToast();

  const isEnabledNotificationsSound = useSelector(
    getIsEnabledNotificationsSoundSelector
  );

  const messageHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) {
        return;
      }

      switch (action) {
        case WsActionTypes.Send: {
          const {
            contact,
            user,
            message: newMessage,
            front_message_id,
          } = event.data as {
            user: UserContact;
            contact: UserContact;
            message: ChatMessage;
            front_message_id?: string;
          };

          if (!contact || !user || !newMessage) return;

          dispatch(
            addNewMessageFromSocketThunk({
              message:
                !newMessage.is_incoming && front_message_id
                  ? { ...newMessage, front_message_id }
                  : newMessage,
              contactId: contact.ulid_id,
              userId: user.ulid_id,
            })
          );

          dispatch(
            updateDialogLastMessageIncomingStatus({
              contactId: contact.ulid_id,
              isIncoming: newMessage?.is_incoming ? 1 : 0,
            })
          );

          if (newMessage?.is_incoming) {
            dispatch(
              removeTypingContact({
                contactId: contact.ulid_id,
                userId: user.ulid_id,
              })
            );

            if (newMessage.id && !newMessage.request_id) {
              TrackingApi.trackMessageReceived({
                messageId: newMessage.id,
                contactId: contact.ulid_id,
              });
            }

            if (newMessage.request_id) {
              TrackingApi.trackRequestReceived({
                requestId: newMessage.request_id,
              });
            }

            if (isEnabledNotificationsSound) {
              audio?.play()?.catch(() => {});
            }

            if (!isDialogsPage(pathname) || document.hidden) {
              tabBadge.updateNotificationCounter(tabBadge.value + 1);
            }
          }

          if (!newMessage?.is_incoming) {
            if (newMessage.id && !newMessage.request_id)
              TrackingApi.trackMessageSent({
                messageId: newMessage.id,
                contactId: contact.ulid_id,
              });

            if (newMessage.request_id)
              TrackingApi.trackRequestSent({
                requestId: newMessage.request_id,
              });
          }

          break;
        }

        case WsActionTypes.Fail: {
          const {
            contact,
            user,
            message: newMessage,
            front_message_id,
          } = event.data as {
            user: UserContact;
            contact: UserContact;
            message: ChatMessage;
            front_message_id?: string;
          };

          if (!newMessage || !contact || !user) return;

          dispatch(
            addNewMessageFromSocketThunk({
              message:
                !newMessage.is_incoming && front_message_id
                  ? { ...newMessage, front_message_id }
                  : newMessage,
              contactId: contact.ulid_id,
              userId: user.ulid_id,
            })
          );

          break;
        }

        case WsActionTypes.Read: {
          const { contactId, userId } = event.data as {
            contactId: string;
            userId: string;
          };

          if (!contactId || !userId) {
            return;
          }

          if (!event?.data?.incoming)
            dispatch(markMessagesAsRead({ contactId, userId }));

          dispatch(
            updateDialogReadStatus({
              contactId,
              userId,
              isIncoming: event?.data?.incoming,
            })
          );

          break;
        }

        case WsActionTypes.RemoveNewBadge: {
          const contactId = event?.data?.contact;
          const userId = event?.data?.user;

          if (!contactId || !userId) {
            return;
          }

          dispatch(markDialogAsNotNew({ contactId, userId }));

          break;
        }

        case WsActionTypes.DeleteMessage: {
          const { dialog, message_id: messageId } = event?.data as {
            dialog: ChatDialog;
            message_id: number;
          };

          if (!dialog || !messageId) return;

          dispatch(removeDialogMessage({ messageId, updatedDialog: dialog }));

          break;
        }

        case WsActionTypes.Typing: {
          const contactId = event?.data?.contact as string;
          const userId = event?.data?.user as string;

          if (!contactId) return;

          dispatch(addTypingContact({ contactId, userId }));

          if (typingContactsTimeouts.current[`${contactId}-${userId}`]) {
            clearTimeout(
              typingContactsTimeouts.current[`${contactId}-${userId}`]
            );
          }

          typingContactsTimeouts.current[`${contactId}-${userId}`] = setTimeout(
            () => {
              dispatch(removeTypingContact({ contactId, userId }));
            },
            CONTACT_TYPING_INTERVAL
          );

          break;
        }

        default:
          break;
      }
    },
    [dispatch, isEnabledNotificationsSound, pathname]
  );

  const notificationsHandler = useCallback(
    (event: any) => {
      if (!event?.data) return;

      const { action } = event.data;

      if (!action) {
        return;
      }

      switch (action) {
        case WsActionTypes.PhotoModerationFail: {
          const message = event.data?.message as string;

          if (!message) return;

          showWarningToast({
            title: event.data.message,
            onClick: () => {
              history.push('/my-profile/edit');
            },
          });

          break;
        }

        case WsActionTypes.NewNotification:
        case WsActionTypes.RepeatNotification: {
          const notification = event.data?.notification as INotification;

          if (!notification?.title || !notification?.description) return;

          showNotificationToast({
            name: notification.title,
            message: notification.description,
            photo:
              MirrorService.resolveImagePath(
                notification?.sender?.main_photo?.big_url
              ) ?? getPhotoPlaceholders(notification?.sender?.gender, 1)[0],
            onClick: () => {
              if (notification.receiver)
                setXUlidId(notification.receiver.ulid_id);

              dispatch(
                readNotificationThunk({ notificationId: notification.id })
              );

              if (!notification.sender || !notification.receiver) return;

              if (notification?.type === NotificationTypes.Inbox) {
                history.push(
                  `/mails/${notification.sender.ulid_id}?${QueryKeys.XUlid}=${notification.receiver.ulid_id}`
                );

                return;
              }

              history.push(
                `/dialogs/${notification.sender.ulid_id}?${QueryKeys.XUlid}=${notification.receiver.ulid_id}`
              );
            },
          });

          if (action === WsActionTypes.NewNotification) {
            dispatch(addNewNotification(notification));
          }

          break;
        }

        case WsActionTypes.DeletedNotification:
        case WsActionTypes.DoneNotification: {
          const notificationId = event.data?.notification_id as number;

          if (!notificationId) return;

          setTimeout(() => {
            dispatch(removeNotification({ notificationId }));
          }, DELAY_FOR_NOTIFICATION_REMOVE);

          break;
        }

        case WsActionTypes.ForceLogout: {
          dispatch(logout());
          break;
        }

        default:
          break;
      }
    },
    [dispatch, history, showNotificationToast, showWarningToast]
  );

  const mailsHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) {
        return;
      }

      // eslint-disable-next-line sonarjs/no-small-switch
      switch (action) {
        case WsActionTypes.InMailSend:
          {
            const {
              user,
              contact,
              inmail: newInMail,
            } = event.data as {
              user: UserContact;
              contact: UserContact;
              inmail: Mail;
            };

            if (!newInMail || !contact || !user) return;

            dispatch(
              addNewMailFromSocketThunk({
                message: newInMail,
                userId: user.ulid_id,
                contactId: contact.ulid_id,
              })
            );
          }
          break;

        default:
          break;
      }
    },
    [dispatch]
  );

  const limitsHandler = useCallback(
    (event: any) => {
      if (!event?.data) return;

      const { action } = event.data;

      if (action === WsActionTypes.LimitChanged) {
        const { contact, user, limits } = event.data as {
          contact: UserContact;
          user: UserContact;
          limits: ChatLimits;
        };

        if (!contact || !user || !limits) return;

        dispatch(
          updateChatContactLimits({
            limits,
            userId: user.ulid_id,
            contactId: contact.ulid_id,
          })
        );
        dispatch(
          updateMailsChatContactLimits({
            limits,
            userId: user.ulid_id,
            contactId: contact.ulid_id,
          })
        );
      }
    },
    [dispatch]
  );

  const systemHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) {
        return;
      }

      if (action === WsActionTypes.UnreadCounters) {
        const unreadCounters = event?.data?.unread_counters as Record<
          string,
          IUnreadCounters
        >;

        if (!unreadCounters) return;

        dispatch(updateUnreadCounters(unreadCounters));

        return;
      }

      if (action === WsActionTypes.TabCounters) {
        const tabCounters = event?.data?.tab_counters as Record<
          string,
          ChatDialogTabCounters
        >;

        if (!tabCounters) {
          return;
        }

        dispatch(setDialogsCounters(tabCounters));
      }

      if (action === WsActionTypes.UserBlockedByContact) {
        const contactId = event?.data?.blocked_by as string;
        const userId = event?.data?.blocked_ulid as string;

        if (!contactId || !userId) return;

        dispatch(markChatAsBlocked({ contactId, userId }));
        dispatch(markMailsChainAsBlocked({ contactId, userId }));
      }

      if (action === WsActionTypes.TabCounters) {
        const tabCounters = event?.data?.tab_counters as Record<
          string,
          ChatDialogTabCounters
        >;

        if (!tabCounters) {
          return;
        }

        dispatch(setDialogsCounters(tabCounters));
      }
    },
    [dispatch]
  );

  const mediaAccessHandler = useCallback(
    (event: any) => {
      if (!event.data) {
        return;
      }

      const { owner, user, accesses, mass_accesses } = event.data as {
        owner: string;
        user: string;
      } & MediaAccess;

      dispatch(
        setProfileMediaAccess({
          userId: owner,
          contactId: user,
          access: {
            accesses,
            mass_accesses,
          },
        })
      );
    },
    [dispatch]
  );

  const presentsHandler = useCallback(
    (event: any) => {
      const action = event?.data?.action;

      if (!action) return;

      if (action === WsActionTypes.PresentStatusChange) {
        const presentInfo = event.data?.real_gift_process as PresentRequest;

        if (!presentInfo || !presentInfo.receiver || !presentInfo.sender)
          return;

        dispatch(addNewPresentRequest(presentInfo));

        if (presentInfo.status === PresentStatus.Requested) {
          dispatch(increasePresentsRequestsCount());
        }
      }
    },
    [dispatch]
  );

  return {
    messageHandler,
    notificationsHandler,
    limitsHandler,
    mailsHandler,
    systemHandler,
    mediaAccessHandler,
    presentsHandler,
  };
};
